import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';

interface AnimatedSectionProps {
    id: string;
    imageSrc: string;
    blurredImageSrc: string;
    children: React.ReactNode;
    index: number;
}

const AnimatedSection: React.FC<AnimatedSectionProps> = ({ id, imageSrc, blurredImageSrc, children, index }) => {
    const [scrollY, setScrollY] = useState(0);
    const sectionRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const boxHeight = 400; // Adjust as needed
    const gap = 800; // Adjust as needed
    const topGap = 300; // Adjust as needed

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (sectionRef.current && imageRef.current && contentRef.current) {
            const sectionTop = sectionRef.current.offsetTop;
            const sectionHeight = sectionRef.current.offsetHeight;
            const viewportHeight = window.innerHeight;

            const startPoint = sectionTop - viewportHeight;
            const endPoint = sectionTop + sectionHeight;

            const progress = (scrollY - startPoint) / (endPoint - startPoint);

            if (progress >= 0 && progress <= 1) {
                const imagePosition = topGap + (index * (boxHeight + gap)) - (progress * boxHeight);
                imageRef.current.style.transform = `translateY(${imagePosition}px)`;
                imageRef.current.style.opacity = (1 - progress).toString();

                contentRef.current.style.opacity = progress.toString();
            }
        }
    }, [scrollY, index]);

    return (
        <section id={id} ref={sectionRef} className="min-h-screen flex flex-col items-center justify-center relative">
            <div ref={imageRef} className="w-full max-w-4xl mx-auto p-8 absolute top-0 left-0 right-0">
                <Image
                    width={1210}
                    height={403}
                    src={imageSrc}
                    alt="Hero Image"
                    placeholder="blur"
                    blurDataURL={blurredImageSrc}
                    style={{
                        width: "100%",
                        height: "auto",
                        maxWidth: "100%",
                        maxHeight: "100%",
                    }}
                />
            </div>
            <div ref={contentRef} className="w-full max-w-4xl mx-auto p-8 relative z-10">
                {children}
            </div>
        </section>
    );
};

export default AnimatedSection;