import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Tooltip from './Tooltip';
import AnimatedSection from './AnimatedSection';

const Home: React.FC = () => {
  const contractAddress = "0xA2b9436D567A740357ca432b35582E93191e6a2F";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(contractAddress);
  };

  return (
    <AnimatedSection
      id="home"
      imageSrc="/images/content/welcome.png"
      blurredImageSrc="/images/content/blurred_welcome.png"
      index={0}
    >
      <h1>v are the original</h1>
      <div className="w-full center-content m-5 p-5">
        <Tooltip text="Click to copy" onCopy={copyToClipboard} showOnClick={true} useDiv={true}>
          <button
            className="copy-button"
            aria-label="Copy token to clipboard"
          >
            CA {contractAddress} <FontAwesomeIcon icon={faCopy} width={24} height={24} />
          </button>
        </Tooltip>
      </div>
      <hr />
      <div className="w-full text-3xl center-content">
        <p className="text-3xl text-center">
          Stand with us, be the spark
          <br />
          In this revolution&apos;s arc,
          <br />
          A new era is in sight,
          <br />
          Join the movement, claim your right.
        </p>
      </div>
    </AnimatedSection>
  );
};

export default Home;